import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import Relation from '@/dc-it/types/Relation'
import User from '@/dc-it/models/User'
import Select from '@/dc-it/types/Select'

export default class Reserve extends Model {
    endpoint = 'reserves';

    related = ['user']

    types = {
      1: 'Interés bruto',
      2: 'Interés neto',
    }

    fields = [
      new Field('id', 'Código').disabled(),
      new Relation('user', 'Usuario', User, 'email').disabled(),
      new Field('concept', 'Concepto'),
      new Select('type', 'Tipo', this.types),
      new Field('percentage', 'Porcentaje', 'number').applySuffix('%'),
    ];

    clone = () => Reserve;
}
